@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('./assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url('./assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Thin';
  src: url('./assets/fonts/Poppins-Thin.ttf') format('truetype');
}


$green-key-color: rgb(84, 178, 59);
$light-grey-key-color: #ededed;
$grey-key-color: #2c2c2c;
$box-shadow:0 39px 67px 0px rgba(0, 0, 0, 0.1);


.b-green {
  background-color: rgba(0, 255, 0, 0.1);
}

.b-red {
  background-color: rgba(255, 0, 0, 0.1);
}

.b-red {
  background-color: rgba(0, 0, 255, 0.1);
}
