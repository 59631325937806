/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "./global";


#guide-container {
  position: absolute;
  top: 0;
  width: 100%;
  transform: translateX(50%);
  opacity: 0.5;

  #guide {
    transform: translateX(-50%);
    width: 1920px;
  }

  #guide-mobile {
    transform: translateX(-50%);
    width: 375px;
  }
}

body {
  position: relative;

  background-color: rgb(44, 44, 44);

  .arrow-left {
    line-height: 0rem;

    svg {
      width: 14px;
      fill: white;
      margin-left: 5px;
    }

    &.bullet {
      line-height: 0;

      svg {
        width: 12px;
        fill: $green-key-color;
        margin-left: 5px;
      }
    }
  }

  #background-container {
    position: absolute;
    z-index: -2;
    top: 0;
    width: 100%;
    transform: translateX(50%);

    #background {
      transform: translateX(-50%);
      max-width: 1920px;
      width: 100%;
    }
  }

  app-header {
    position: fixed;
    width: 100%;
    z-index: 1000;
    transition: background-color 1s;

    nav {
      transition: border-bottom-color 1s
    }

    &.dark-header {
      background-color: #2a2a2a !important;

      nav {
        border-bottom-color: transparent;
      }
    }
  }

  app-landing {
    padding-top: 11.375rem;
    display: block;
  }

  @media screen and (max-width: 420px) {
    #background-container {

      #background {
        transform: translateX(-65.5%);
        width: 1920px;
      }
    }
  }

}

.container-1350 {
  max-width: 1350px;
  margin: 0 auto;
  padding-left: 5.4rem;
}

a.button {
  border: 1px solid white;
  border-radius: 1px;

  &.green-background {
    border: none;
    background-color: $green-key-color;
    box-shadow: -1px 3px 20px 0px rgba(84, 178, 59, 0.4);
  }

  &.grey-background {

    box-shadow: -1px 3px 20px 0px rgba(0, 0, 0, 0.4);
    border: none;
    background-color: $grey-key-color;
  }

  display: flex;
  padding: 1.1rem 2.0rem;
  color: white;
  font-size: 16px;
  font-family: 'Poppins-Regular';
  justify-content: center;
  align-items: center;
}

span.arrow {
  //TODO
  font-family: "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto";
}
